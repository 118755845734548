<template>
  <div class="content">
    <header-nav></header-nav>
    <div class="banner">
      <img :src="require('@/assets/images/kp2.jpg')" alt="" />
      <div class="body-content">
        <div class="pay-flex">
          <div class="paycode">
            <div class="paybox">
              <div class="code-img">
                <img :src="require('@/assets/images/alipay.png')" alt="" />
              </div>
              <div class="wenzi">支付宝支付</div>
            </div>
            <div class="paybox">
              <div class="code-img">
                <img :src="require('@/assets/images/weichat.png')" alt="" />
              </div>
              <div class="wenzi">微信支付</div>
            </div>
          </div>
          <div class="tit-hit">
            <div class="big-font">尊敬的用户，您好:</div>
            <div class="lit-font">
              您的免费使用权限已用完，想要继续使用，请扫码支付开通权限。
            </div>
            <div @click="showMsg" class="know-more">
              点击此处，联系客服，了解更多版本权限详细内容
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="蓝目策略训练器"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="version" label="版本号" width="100">
        </el-table-column>
        <el-table-column prop="name" label="版本名称" width="100">
        </el-table-column>
        <el-table-column prop="price" label="价格" width="100"> </el-table-column>
        <el-table-column prop="aging" label="时效" width="100"> </el-table-column>
        <el-table-column prop="limits" label="使用权限" > </el-table-column>
      </el-table>
      <div class="partner">
        <div class="big-lable">支持券商:</div>
        <div class="partner-img">
          <img :src="require('@/assets/images/njzq.png')" alt="" />
        </div>
        <div class="partner-img">
          <img :src="require('@/assets/images/chuangy.jpg')" alt="" />
        </div>
        <div class="partner-img">
          <img :src="require('@/assets/images/dongya.png')" alt="" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <floor></floor>
  </div>
</template>
<script>
import HeaderNav from "@/components/Header.vue";
import Floor from "@/components/Floor.vue";
export default {
  components: {
    HeaderNav,
    Floor,
  },
  data() {
    return {
      dialogVisible: false,
      tableData:[
        {version:'1001',name:'训练版',price:"免费",aging:'永久',limits:'每日测评、回测3次，模拟交易任务单10条，不能提交云端'},
        {version:'3020',name:'交易版',price:"500",aging:'1月',limits:'无限次，可提交云端监控'},
        {version:'3021',name:'交易版',price:"1000",aging:'3月',limits:'无限次，可提交云端监控'},
        {version:'3022',name:'交易版',price:"1500",aging:'6月',limits:'无限次，可提交云端监控'},
        {version:'3023',name:'交易版',price:"2500",aging:'1年',limits:'无限次，可提交云端监控'},
        {version:'3024',name:'交易版',price:"8000",aging:'1年',limits:'无限次，可提交云端监控，连接券商交易通道，开通全自动交易'},
        ]
    };
  },
  methods: {
    showMsg() {
      console.log(789);
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  min-width: 1200px;
  margin: 0;
  padding: 0;
  position: relative;
  img {
    width: 100%;
  }
}
.body-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  align-items: center;
  .pay-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .paycode {
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: flex-end;
    .paybox {
      width: 240px;
      height: 280px;
      margin: 0 20px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      .code-img {
        width: 200px;
        img {
          width: 100%;
        }
      }
      .wenzi {
        font-size: 26px;
        padding: 10px;
      }
    }
  }
  .tit-hit {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 40%;
    text-align: left;
    padding-left: 30px;
    color: #ffffff;
    .big-font {
      font-size: 40px;
      margin-bottom: 15px;
    }
    .lit-font {
      font-size: 30px;
    }
    .know-more {
      cursor: pointer;
      width: 450px;
      display: flex;
      justify-content: center;
      font-size: 20px;
      margin-top: 20px;
      padding: 10px 20px;
      border-radius: 4px;
      background-color: #5ca1ff;
    }
  }
}
.partner{
  width: 100%;
  margin: 20px 10px;
  display: flex;
  align-items: center;
  .big-lable{
    font-size: 22px;
    color: #000000;
    font-weight: 600;
  }
  .partner-img{
    padding-left: 20px;
    height: 40px;
    img{
      height: 100%;
    }
  }
  }
</style>